<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'SpecialWorkingDays' }"
            >Special Working Days</router-link
          >
        </li>
        <li class="breadcrumb-item active">Edit</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row align-items-center">
        <div class="col-4">
          <h2 class="title2">Update Special Working Days</h2>
        </div>
        <div class="col-2 text-end">
          <router-link
            :to="{ name: 'SpecialWorkingDays' }"
            class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <form @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-lg-6 mb-3">
            <div class="card">
              <div class="card-header"></div>
              <div class="card-body">
                <div class="mb-3 date-wrapper">
                  <label class="form-label">Date</label>
                  <datepicker
                    :typeable="true"
                    placeholder="Select Date"
                    class="form-control"
                    v-model="$v.form.date.$model"
                    :validator="$v.form.date"
                    :format="customFormatter"
                    :highlighted="highlighted"
                    :disabled-dates="datePickerOption"
                    :opened="holidays()"
                  ></datepicker>
                  <span class="error" v-if="$v.form.date.$error == true"
                    >Date is required</span
                  >
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Save
                  </button>
                  <router-link
                    :to="{ name: 'SpecialWorkingDays' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
export default {
  components: { Datepicker },
  data() {
    return {
      form: {
        date: null,
      },
      formUpdated: false,
      highlighted: { dates: [new Date("yyyy-MM-dd")] },
      datePickerOption: {
        to: new Date(Date.now() - 8640000),
      },
    };
  },
  validations: {
    form: {
      date: {
        required,
      },
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadSpecialWorkingDays");
  },
  mounted() {
    window.setTimeout(this.updateSpecialWorkginDayForm, 1000);
  },
  computed:{
    holidaydata() {
      var holidaysData = this.$store.state.holidaysData;
      var newholidaydata = holidaysData.map(function (val) {
        return val.date;
      });
      return newholidaydata;
    }
  },
  methods: {
    holidays() {
      var holiday = this.holidaydata;
      var currentYear = new Date().getFullYear();
      this.datePickerOption.to = new Date(currentYear, 0, 1);
      this.datePickerOption.customPredictor = function (date) {
        if (
          date.getDay() != 0 &&
          date.getDay() != 6 &&
          !holiday.includes(moment(date).format("YYYY-MM-DD"))
        ) {
          return true;
        }
      };
      //return (this.datePickerOption.dates = this.holidaydata);
    },
    updateSpecialWorkginDayForm() {
      let specialWorkingDaysData =
        this.$store.state.specialWorkingDays.data.filter((holiday) => {
          return holiday.id == this.$route.params.id;
        });
      this.form.id = this.$route.params.id;
      this.form.date = specialWorkingDaysData[0].date;
      this.formUpdated = true;
    },
    customFormatter(date) {
      let formateedDate = moment(date).format("yyyy-MM-DD");
      this.form.date = formateedDate;
      return formateedDate;
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      axios
        .post("update/specialworkingdays", this.form)
        .catch(({ response }) => {
          this.flashMessage.error({
            message: response.data.error,
            time: 3000,
          });
        })
        .then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "SpecialWorkingDays" });
          }
        });
    },
    onChangeFileUpload() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>
